import React, { useState } from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import FullpageWrapper from "../components/fullpagewrapper2"
import SEO from "../components/seo"
import "./directorDetail.sass"

const Slideshow = ({ data }) => {
  const [video, setVideo] = useState({ film: null, playlist: null })
  return (
    <>
      <Helmet htmlAttributes={{ class: "home" }} />
      <Layout
        region={data.contentfulSlideshow.region.slug}
        video={video.film}
        list={video.playlist}
        close={() => {
          setVideo({})
        }}
      >
        <SEO title={data.contentfulSlideshow.region.name} />
        <FullpageWrapper
          slides={data.contentfulSlideshow.slides}
          togglePlayer={(video, playlist) => {
            setVideo({
              film: video,
              playlist: playlist,
            })
          }}
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query Slideshow($slideshowId: String) {
    contentfulSlideshow(id: { eq: $slideshowId }) {
      id
      region {
        slug
        name
      }
      films {
        id
        title
        brand
        url
        shortVideo
        director {
          id
          name
          region {
            slug
          }
        }
        cover {
          id
          localFile {
            id
            url
          }
        }
      }
      slides {
        ... on ContentfulSlide {
          id
          title
          film {
            id
            title
            brand
            url
            shortVideo
            director {
              id
              name
              region {
                slug
              }
            }
            cover {
              id
              localFile {
                id
                url
              }
            }
          }
          welcome {
            raw
          }
          subtitle {
            raw
          }
        }
      }
    }
  }
`

export default Slideshow
