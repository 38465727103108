import React from "react"
import _ from "underscore"

class BackgroundVideoSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { height: 0, width: 0, left: 0, top: 0 }
    this._handleResize = _.debounce(this._handleResize.bind(this), 100)
  }

  _handleResize() {
    const ratio = 16 / 9
    let height = this.props.height ? document.querySelector(this.props.height).offsetHeight : window.innerHeight
    let width = window.innerWidth
    let left = 0
    let top = 0
    if (height * ratio > width) {
      top = 0
      left = -(width - height * ratio) / 2
      width = height * ratio
    } else {
      left = 0
      top = -(height - width / ratio) / 2
      height = width / ratio
    }
    this.setState({
      height: height,
      width: width,
      left: left,
      top: top,
    })
  }

  componentDidMount() {
    this._handleResize()
    window.addEventListener("resize", this._handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._handleResize)
  }

  render() {
    return (
      <>
        {this.props.children}
        <div
          style={{
            position: `absolute`,
            top: -this.state.top,
            left: -this.state.left,
            height: this.state.height,
            width: this.state.width,
            zIndex: -1000,
          }}
        >
          <video style={{ height: `100%`, backgroundSize: `100% 100%`}} loop muted autoPlay playsInline poster={this.props.videoPoster}>
            <source src={this.props.videoUrl} type="video/mp4" />

          </video>
        </div>
      </>
    )
  }
}

export default BackgroundVideoSection
