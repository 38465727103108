import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import BackgroundVideoSection from "../components/backgroundvideosection"
import "./fullpagewrapper.sass"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"

const H1 = styled.h1`
  font-size: 6rem;
  font-weight: bold;
  line-height: 6rem;

  @media (max-width: 450px) {
    font-size: 2rem;
    line-height: 3.5rem;
  }
`

const H2 = styled.h2`
  font-size: 1rem;
  font-weight: bold;
`

const Bold = ({ children }) => <span className="bold">{children}</span>;

const Text = ({ children }) => <p className="align-center">{children}</p>;

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

class FullpageWrapper extends React.Component {

  constructor(props) {
    super(props)
    this._mouseListener = () => {
      this.resetTimer()
    }
  }

  onLeave(origin, destination, direction) {
    const playPromise = destination.item.querySelector("video").play()
    playPromise.then().catch(e => console.log("Playing failed"))
  }

  handleClick(video, playlist) {
    let filmPlaylist = playlist.map(slide => slide.film)
    this.props.togglePlayer(video, filmPlaylist)
  }

  componentDidMount() {
    window.addEventListener("mousemove", this._mouseListener)
    window.addEventListener("wheel", this._mouseListener)
    window.addEventListener("touchmove", this._mouseListener)
    this.resetTimer()
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this._mouseListener)
    window.removeEventListener("wheel", this._mouseListener)
    window.removeEventListener("touchmove", this._mouseListener)
    this.resetTimer(true)
  }

  resetTimer(stop = false) {
    clearInterval(window.timer)
    if (stop) return
    window.timer = setInterval(()=> {
      if (!document.querySelector('html').classList.contains('player')) {
        window.fullpage_api.moveSectionDown()
      }
    }, 6000)
  }

  render() {
    const { slides } = this.props
    return (
      <ReactFullpage
        navigation
        licenseKey={"1A9F920E-7C6A4173-85C73AD7-EE187856"}
        scrollHorizontallyKey={"6F55F38B-F7AF4F9A-AFB117E1-06DD9D55"}
        scrollHorizontally={true}
        verticalCentered={false}
        scrollingSpeed={1000}
        loopBottom={true}
        onLeave={this.onLeave.bind(this)}
        render={(comp) => {
          return (
            <ReactFullpage.Wrapper>
              {slides
                .filter(slide => {
                  return slide.film.shortVideo
                })
                .map(slide => {
                  let film = slide.film
                  return (
                    <div
                      key={film.id}
                      className="section"
                      style={{
                        overflow: `hidden`,
                        position: `relative`,
                        cursor: `pointer`,
                      }}
                      onClick={() => {
                        this.handleClick(film, slides)
                      }}
                    >
                      <BackgroundVideoSection videoUrl={film.shortVideo} videoPoster={film.cover.localFile.url}>
                        <div className="slide">
                          <div className="title-block">
                            { (slide.welcome || slide.subtitle) &&
                              <>
                                <H1>{renderRichText(slide.welcome, options)}</H1>
                                <H2>{renderRichText(slide.subtitle, options)}</H2>
                              </>
                            }
                            { (!slide.welcome && !slide.subtitle) &&
                              <>
                                <H1>{film.director.name}</H1>
                                <H2>
                                  {film.brand} <span>— {film.title}</span>
                                </H2>
                              </>
                            }
                          </div>
                        </div>
                      </BackgroundVideoSection>
                    </div>
                  )
                })}
            </ReactFullpage.Wrapper>
          )
        }}
      />
    )
  }
}

export default FullpageWrapper
